import API from '../utils/API'

export default {
  bestMerchant (data) {
    return API({
      url: 'bestMerchant',
      data,
      method: 'POST',
      requestId: 'getMerchantData'
    })
  },
  getOffersCategory (data) {
    return API({
      url: 'homeSections',
      data,
      method: 'POST',
      requestId: 'homeSections'
    })
  },

  getNearestOffers (data) {
    return API({
      url: 'filterOffers',
      data,
      method: 'POST',
      requestId: 'filterOffers'
    })
  },

  getSectionOffers (data) {
    return API({
      url: 'sectionOffers',
      data,
      method: 'POST',
      requestId: 'sectionOffers'
    })
  },
  getSlider (data) {
    return API({
      url: 'webSlider',
      data,
      method: 'POST',
      requestId: 'webSlider'
    })
  },
  getStatistics (data) {
    return API({
      url: 'statistics',
      data,
      method: 'POST',
      requestId: 'getStatistics'
    })
  }
}
