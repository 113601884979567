<template>
  <div>
    <div
      class="base-advanced-filter d-flex justify-content-between align-items-start"
    >
      <div class="all-categories">
        <div
          class="categories d-flex justify-content-start align-items-center overflow-x-scroll hidden-scroll flex-no-wrap"
        >
          <div
            v-for="item in items"
            :key="item.id"
            class="category cursor-pointer d-flex justify-content-start align-items-center ml-3"
            :class="{ isNotActive: item.id === activeId }"
            @click="switchTabs(item)"
          >
            <div
              class="icon"
              :style="[{ background: item.background_color }]"
            >
              <img
                alt=""
                :src="item.image"
              >
            </div>

            <p class="fs-14 medium-font m-l-8">
              {{ item.name }}
            </p>
          </div>
        </div>

        <div
          class="sub-categories m-t-20 d-flex justify-content-start align-items-center overflow-x-scroll hidden-scroll flex-no-wrap"
        >
          <div
            v-for="item in subItems"
            :key="item.id"
            class="sub-category cursor-pointer p-y-8 p-x-16 m-r-14"
            :class="{ active: item.id === activeIdSubId }"
            @click="switchSubTabs(item.id)"
          >
            <p class="fs-16 medium-font">
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>

      <div class="filters">
        <BaseDropdown
          v-if="cities.length"
          input-id="cities"
          class="mx-0 mt-3 fix-res mt-md-0 mx-md-1 mb-3 remove-class-form-small-screen reduce-width"
          :options="cities"
          :value="citiesValue"
          @update-seletced="updateSelectedCity"
        />
        <BaseDropdown
          class="mx-sm-2 mt-3 mt-md-0 mb-3 middle-mar remove-class-form-small-screen reduce-dropdown-height"
          input-id="prices"
          :options="prices"
          :value="pricesValue"
          @update-seletced="updateSelectedPrice"
        />
      </div>
    </div>
    <!-- To Be discussed -->
    <!-- <div class="reset-container mt-3 mx-sm-2 mb-3">
      <span
        v-if="pricesValue || activeId || activeIdSubId || citiesValue"
        class="reset-button"
        @click="reset"
      >
        {{ $t('offers.reset') }}
      </span>
    </div> -->
  </div>
</template>

<script>
import BaseDropdown from '@/components/partials/base-dropdown/Index.vue'
import Locations from '@/services/locations'
import { EventBus } from '../../../utils/event-bus'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'BaseAdvancedFilter',
  components: { BaseDropdown, VueSlickCarousel },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    subItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      activeId: 0,
      activeIdSubId: 0,
      // make static data to use it in filter
      prices: [
        {
          id: 'price-asc',
          value: 'price-asc',
          name: 'offers.priceAsc'
        },
        {
          id: 'price-desc',
          value: 'price-desc',
          name: 'offers.priceDesc'
        }
      ],

      settings: {
        infinite: true,
        initialSlide: 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true
      },
      citiesValue: '',
      pricesValue: ''
    }
  },
  computed: {
    cities () {
      return this.$store.getters['locations/cities']
    }
  },
  watch: {
    '$route.query' () {
      if (isNaN(parseFloat(this.$route.query.categoryId))) {
        this.activeId = this.$route.query.categoryId ? this.$route.query.categoryId : ''
      } else {
        this.activeId = this.$route.query.categoryId ? +this.$route.query.categoryId : ''
      }
      this.citiesValue = this.$route.query.city ? +this.$route.query.city : ''
      this.pricesValue = this.$route.query.price ? this.$route.query.price : ''
      this.activeIdSubId = this.$route.query.subId ? +this.$route.query.subId : ''
    }
  },

  created () {
    this.filterCities()
    if (isNaN(parseFloat(this.$route.query.categoryId))) {
      this.activeId = this.$route.query.categoryId ? this.$route.query.categoryId : ''
    } else {
      this.activeId = this.$route.query.categoryId ? +this.$route.query.categoryId : ''
    }
    this.citiesValue = this.$route.query.city ? +this.$route.query.city : ''
    this.pricesValue = this.$route.query.price ? this.$route.query.price : ''
    this.activeIdSubId = this.$route.query.subId ? +this.$route.query.subId : ''
  },

  methods: {
    reset () {
      this.$emit('resetFilters')
    },

    filterCities () {
      this.$store.dispatch('locations/getAllCountries')
    },

    // to route me to single offer page
    switchTabs (itemId) {
      // this.activeId = itemId.id || this.$route.query.categoryId
      this.activeId = itemId.id

      this.$emit('switchOffers', { id: itemId.id, name: itemId.name })

      this.activeIdSubId = 1
      // in case want to reset filter cities
      // this.citiesValue = ''

      EventBus.$emit('ChangeTitlePage', { id: itemId.id, name: itemId.name })
    },

    switchSubTabs (subItemId) {
      if (subItemId == this.activeIdSubId) {
        subItemId = ''
      }
      this.activeIdSubId = subItemId
      this.$emit('switchSubOffers', subItemId)
    },

    updateSelectedCity ({ id, value }) {
      this.citiesValue = value
      this.$emit('updatedCity', value)
    },

    updateSelectedPrice ({ id, value }) {
      this.pricesValue = value
      this.$emit('updatePrice', value)
    }
  }
}
</script>

<style lang="scss">
@import './base-advanced-filter';
.reduce-dropdown-height .dropdown-body {
  overflow: hidden;
  height: 130px !important;
  width: 150px !important;
}

.reduce-width .dropdown-body {
  height: 180px;
  width: 140px !important;
}
</style>
