<template>
  <div class="base-offer-card">
    <div class="cover-container">
      <div class="loader-dots" />
      <!-- <VueSkeletonLoader
        v-show="offerLoading"
        class="img-container"
        type="rect"
        :width="800"
        :height="230"
        animation="fade"
      /> -->
    </div>

    <div class="content white-color-bg">
      <div class="content-container">
        <div class="offer-place d-flex justify-content-between align-items-end">
          <div class="place-name d-flex justify-content-start align-items-end">
            <div class="img-container">
              <!-- <img> -->
              <!-- <VueSkeletonLoader
                v-show="offerLoading"
                class=""
                type="circle"
                :width="60"
                :height="60"
                animation="fade"
              /> -->
            </div>

            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="medium-font light-gray-color-text"
              type="rect"
              :width="150"
              :height="20"
              animation="fade"
            /> -->
          </div>
        </div>

        <div
          class="offer-title d-flex justify-content-between align-items-center"
        >
          <router-link
            to=""
            tag="p"
            class="bold-font dark-gray-color-text"
          />
        </div>

        <div
          class="offer-price d-flex justify-content-between align-items-center"
        >
          <div class="price d-flex justify-content-start align-items-center">
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="ml-3"
              type="rect"
              :width="40"
              :height="20"
              animation="fade"
            />
            <VueSkeletonLoader
              v-show="offerLoading"
              class=""
              type="rect"
              :width="60"
              :height="20"
              animation="fade"
            /> -->
          </div>

          <div class="add-to-favourite">
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class=""
              type="circle"
              :width="25"
              :height="25"
              animation="fade"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'BaseOfferCard',
  // components: { VueSkeletonLoader },
  data () {
    return {
      offerLoading: false
    }
  },
  mounted () {
    this.offerLoading = true
  }
}
</script>

<style lang="scss" scoped>
@import './base-offer-card';
</style>
