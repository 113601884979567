export default {
  globalCityId: 3,
  option: {
    best_seller: 'best_seller',
    recent: 'recent',
    nearest: 'nearest',
    most_rate: 'most_rate',
    hot_deals: 'hot_deals',
    price_asc: 'price-asc',
    price_desc: 'price-desc',
    high_sort: 'high_sort'
  }
}
